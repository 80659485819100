<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient
                            :client_id="client_id"
                            :siggys="original_signature_ids.length"
                            :form_data_id="record_id"
                            @getClient="getClient"
                        /><br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button type="button" @click="signature_ids=[]" v-if="signature_ids.length && original_signature_ids.length">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right" type="button" @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <div class="max800">
                    <h1 style="text-align:center;">Peace Project Mental Health Counseling</h1>
                    <p>PRACTICE POLICIES
                        <br/>The standard meeting time for Telehealth Psychotherapy and Individual Psychotherapy is 50 minutes. It is up to you, however, to determine the length of time of your sessions. Requests to change the 50 minute session needs to be discussed with the health care provider in order for time to be scheduled in advance. Full payment is expected at time of service. Credit card on file is the preferred method of payment (including Health Savings Account/Flex Spending Account), however, we also accept checks.</p>
                    <p>If you are interested in reimbursement from your insurance company, please ask your insurance provider their reimbursement rate for out of network providers, which is generally between 60-90%. If you choose to do this, payment will be expected at time of service, and your therapist will provide an invoice at the end of each month that you will then submit to your insurance company for their reimbursement rate. <b>A $10.00 service charge</b> will be charged for any checks returned for any reason for special handling. Cancellations and re-scheduled sessions will be subject to <b>a full charge if not received at least 24 hours in advance.</b> This is necessary because a time commitment is made to you and is held exclusively for you. If you are late for a session, you may lose some of that session time.</p>
                    <p>TELEPHONE ACCESSIBILITY
                        <br/>If you need to contact me between sessions, please leave a message on my voicemail. I am often not immediately available; however, I will attempt to return your call within 24 business hours. If a true emergency situation arises, please call 911 or any local emergency room.</p>
                    <p>SOCIAL MEDIA AND TELECOMMUNICATION
                        <br/>Due to the importance of your confidentiality and the importance of minimizing dual relationships, we do not accept personal friend or contact requests from current or former clients on any personal social networking site (Facebook, LinkedIn, etc). We believe that adding clients as friends or contacts on these sites can compromise your confidentiality and our respective privacy. It may also blur the boundaries of our therapeutic relationship. We do use our business social media accounts as interactive platforms for our clients, however, they in no way act as professional interventions. If you choose to interact on our social media business accounts, your confidentiality may not be maintained, as outside parties may assume the nature of our relationship. If you have questions about this, please bring them up when we meet and we can talk more about it.</p>
                    <p>ELECTRONIC COMMUNICATION
                        <br/>I cannot ensure the confidentiality of any form of communication through electronic media, including text messages. If you prefer to communicate via email or text messaging for issues regarding scheduling or cancellations, I will do so. While I may try to return messages in a timely manner, I cannot guarantee immediate response and request that you do not use these methods of communication to discuss therapeutic content and/or request assistance for emergencies.</p>
                    <p>MINORS
                        <br/>If you are a minor, your parents may be legally entitled to some information about your treatment. I will discuss with you and your parents what information is appropriate for them to receive and which issues are more appropriately kept confidential.</p>
                    <p>TERMINATION
                        <br/>Ending relationships can be difficult. Therefore, it is important to have a termination process in order to achieve some closure. The appropriate length of the termination depends on the length and intensity of the treatment. I may terminate treatment after appropriate discussion with you and a termination process if I determine that the treatment is not being effectively used or if you are in default on payment. I will not terminate the therapeutic relationship without first discussing and exploring the reasons and purpose of terminating. If treatment is terminated for any reason or you request another provider, I will provide you with a list of qualified Mental Health Professional to treat you. You may also choose someone on your own or from another referral source. Should you fail to schedule an appointment for three consecutive weeks, unless other arrangements have been made in advance, for legal and ethical reasons, I must consider the professional relationship discontinued.</p>
                    <p>BY CLICKING SINGING BELOW I AM AGREEING THAT I HAVE READ, UNDERSTOOD AND AGREE TO THE ITEMS CONTAINED IN THIS DOCUMENT.</p>
                </div>

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />

                </div>
                <FormSignature :user_id="user_id" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
            >
                <SignConvertPdfModal />
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient'
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew'
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex'
    import { merge } from 'lodash'

    export default {
        name: 'PracticePolicies',
        props: {
            record_id: {
                type: [String, Number],
                required : false,
                default : 0
            },
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
            }
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            showCreatePdfModal(newVal){
                if(window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if(newVal == false){this.closeModalOverlay()}
            },
        }
    }
</script>